import {FC, useEffect} from 'react';
import {HilabTheme} from '@PeD/hilib'

import 'config/i18n'
// import { AppContext } from 'contexts/AppContext'
import Switcher from 'components/main/Switcher'
import 'App.css'
import {MQTTContext} from 'contexts/MQTTContext';
import {Theme} from '@mui/material/styles';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {
  }
}

const twentyFourHoursInMs = 1000 * 60 * 60 * 24
const queryClient: QueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 2,
      staleTime: twentyFourHoursInMs,
    },
  },
})

const App: FC = (): JSX.Element => {

  useEffect(() => {
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", "pt-BR")
    }
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      {/*<AppContext>*/}
      <MQTTContext>
        <HilabTheme>
          <Switcher/>
        </HilabTheme>
      </MQTTContext>
      {/*</AppContext>*/}
    </QueryClientProvider>
  );
}

export default App;
