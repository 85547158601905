import {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import Alert from "components/main/Alert";
import i18next from "i18next";
import {Axios} from "./axiosFetcher";
import useAuthStore from "../contexts/AuthStore";
import {IAuthData} from "../types";

async function requestInterceptor(config: AxiosRequestConfig) {
  const authState = useAuthStore.getState();
  const access_token = authState.access_token;
  const refresh_token = authState.refresh_token;
  const timeLimit = authState.expiresAt;
  if (config.url === `/sso/oauth/token`) {
    config.headers = {
      ...config.headers,
    };
  } else {
    try {
      if (refresh_token && timeLimit && timeLimit < new Date().getTime()) {
        try {
          const body = new FormData();
          body.append("grant_type", "refresh_token");
          body.append("refresh_token", refresh_token);
          const res: any = await Axios.post(`/sso/oauth/token`, body, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization:
                "Basic " +
                window.btoa(
                  `${process.env.REACT_APP_USER}:${process.env.REACT_APP_PASSWORD}`
                ),
            },
          }).catch((err) => {
            console.log(err);
            authState.logout();
          });
          if (res.data) {
            config.headers.Authorization = ""
            const body = {};
            authState.refreshToken(res.data as IAuthData)
            if (res.data.access_token) {
              config.headers.Authorization = `Bearer ${res.data.access_token}`;
              config.headers = {
                ...config.headers,
                auth: {
                  username: process.env.REACT_APP_USER!,
                  password: process.env.REACT_APP_PASSWORD!,
                },
              };
            }
            const newTokenSign = authState.tokenSign;
            if (newTokenSign) {
              await Axios.post(
                "/report-software-service/token/signature/refresh",
                body,
                {
                  headers: {
                    tokenSign: newTokenSign,
                  },
                }
              ).then((refreshToken: any) => {
                if (refreshToken && refreshToken.data && refreshToken.data.tokenSign) {
                  authState.setTokenSign(refreshToken.data);
                  config.headers = {
                    ...config.headers,
                    tokenSign: refreshToken.data.tokenSign
                  }
                }
              })
            }
          }
        } catch (err) {
          // window.locaion.href = '/'
        }
      } else {
        if (access_token) config.headers.Authorization = `Bearer ${access_token}`;
        config.headers = {
          ...config.headers,
          auth: {
            username: process.env.REACT_APP_USER!,
            password: process.env.REACT_APP_PASSWORD!,
          },
        };
      }
    } catch (err) {
      if (access_token) config.headers.Authorization = `Bearer ${access_token}`;
      config.headers = {
        ...config.headers,
        auth: {
          username: process.env.REACT_APP_USER!,
          password: process.env.REACT_APP_PASSWORD!,
        },
      };
    }
  }

  return config;
}

function requestInterceptorError(error: AxiosError) {
  return Promise.reject(error);
}

function responseInterceptor(response: AxiosResponse) {
  // console.info('Response Interceptor')
  return response;
}

function responseInterceptorError(error: any) {
  if (error.response) {
    try {
      if (error.response?.status === 401) {
        localStorage.clear();
        window.location.href = "/";
        window.parent &&
        window.parent.postMessage(
          {
            cmd: "unauthorized",
            params: {},
          },
          "*"
        );
      } else if (error.response?.status === 422) {
        Alert.fire({
          toast: true,
          position: "bottom-start",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          text: i18next.t("DEFAULT_REPORT_ERROR_MESSAGE"),
          customClass: {
            container: "stylesAlert ",
          },
        });
      } else {
        let msg = "error";
        if (error.response?.data?.details) {
          msg = i18next.t(error.response?.data?.details[0]?.description) + (error.response?.data?.details[1] ? error.response?.data?.details[1]?.description : '');
        } else if (error.response?.data?.error_description) {
          msg = i18next.t(error.response?.data?.error_description);
        }
        Alert.fire({
          toast: true,
          position: "bottom-start",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          text: msg,
          customClass: {
            container: "stylesAlert ",
          },
        });
      }
    } catch (err) {
      return Promise.reject(error);
    }
  }
  return Promise.reject(error);
}

export {
  requestInterceptor,
  requestInterceptorError,
  responseInterceptor,
  responseInterceptorError,
};
