if (process.env.NODE_ENV === 'production') {
  const con: any = {
    assert: () => { },
    clear: () => { },
    count: () => { },
    debug: () => { },
    dir: () => { },
    dirxml: () => { },
    error: () => { },
    exception: () => { },
    group: () => { },
    groupCollapsed: () => { },
    groupEnd: () => { },
    info: () => { },
    log: () => { },
    markTimeline: () => { },
    profile: () => { },
    profileEnd: () => { },
    table: () => { },
    time: () => { },
    timeEnd: () => { },
    timeline: () => { },
    timelineEnd: () => { },
    timeStamp: () => { },
    trace: () => { },
    warn: () => { },
  }
  window.console = con as Console
}

export {}