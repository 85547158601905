import {Context as TContext, createContext, FC, ReactNode, useContext, useEffect, useState} from "react";
import {ILogin, MQTTStateType} from 'types/index'
import mqtt, {IClientOptions, MqttClient, QoS} from 'mqtt'
import {getAllReportQueues} from 'services/queueService';
import useAuthStore from "contexts/AuthStore";

type TProps = {
  children: ReactNode
}

type TFunctionType = () => MQTTStateType

const Context: TContext<MQTTStateType> = createContext<MQTTStateType>({
  mqttConnect: () => {
  },
  mqttSub: () => {
  },
  mqttUnSub: async () => {
  },
  mqttPublish: () => {
  },
  mqttDisconnect: async () => {
  },
  payload: {
    topic: "",
    message: "",
  },
  setPayload: () => {
  },
  isSub: false,
  saveLogin: () => {
  },
  userMQTT: ""
});

const useMQTT: TFunctionType = () => useContext<MQTTStateType>(Context);

const AppState: TFunctionType = () => {
  const [payload, setPayload] = useState({
    topic: "",
    message: ""
  })
  const {user, access_token, keyPass, setKeyPass} = useAuthStore();
  const [isSub, setIsSub] = useState(false)
  const [client, setClient] = useState(null as MqttClient | null);
  const [, setConnectStatus] = useState("")
  const [userMQTT, setUser] = useState("")
  const mqttConnect = (host: string, mqttOption: IClientOptions) => {
    setConnectStatus('Connecting');
    setClient(mqtt.connect(host, mqttOption));
  };

  const saveLogin = (val: ILogin) => {
    setUser(val.username)
    setKeyPass(btoa(val.password))
    if (!isSub) {
      const url = process.env.REACT_APP_MQTT_HOST;
      if (url) {
        const options = {
          keepalive: 30,
          protocolId: 'MQTT',
          protocolVersion: 4,
          clean: true,
          reconnectPeriod: 1000,
          connectTimeout: 30 * 1000,
          rejectUnauthorized: false,
          username: `reportsw-vhost:${val.username}`,
          password: val.password
        };

        mqttConnect(url, options)
      }
    }
  }

  useEffect(() => {
    reconnectUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reconnectUser = () => {
    setUser("")
    if (access_token && user && keyPass) {
      setUser(user.username + "")
      const url = process.env.REACT_APP_MQTT_HOST;
      if (url) {
        const options = {
          keepalive: 30,
          protocolId: 'MQTT',
          protocolVersion: 4,
          clean: true,
          reconnectPeriod: 1000,
          connectTimeout: 30 * 1000,
          rejectUnauthorized: false,
          username: `reportsw-vhost:${user.username}`,
          password: atob(keyPass)
        };

        mqttConnect(url, options)
      }
    }
  }
  useEffect(() => {
    if (client) {

      if (user) {
        user?.reportQueues?.forEach((queue) => {
          client.on('connect', () => {
            mqttSub({
              topic: queue + '/#',
              qos: 1
            })
          });
          client.on('reconnect', () => {
            mqttSub({
              topic: queue + '/#',
              qos: 1
            })
            setConnectStatus('Reconnecting');
          });
        })

        client.on('error', (err: any) => {
          if (isSub) {
            mqttUnSub();
          }
          client.end();
        });
      }

      client.on('message', (topic: string, message: string) => {
        const payload = {topic, message: message.toString()};
        setPayload(payload);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  const mqttSub = (subscription: { topic: string | string[], qos: QoS }) => {
    if (client) {
      const {topic, qos} = subscription;
      client.subscribe(topic, {qos}, (error: any) => {
        if (error) {
          return
        }
        // console.log('SUUUUUUBED: ', topic)
        setIsSub(true)
      });
    }
  };

  const mqttUnSub = async () => {
    if (client) {
      const topics: any = (await getAllReportQueues()).data;

      if (topics === null) {
        return
      }

      topics?.forEach((topic: string) => {
        client.unsubscribe(topic, (error: any) => {
          if (error) {
            return
          }
          // console.log('UNSUBED')
          setIsSub(false);
        });
      });
    }
  };

  const mqttPublish = (context: { topic: string, qos: QoS, payload: string | Buffer }) => {
    if (client) {
      const {topic, qos, payload} = context;
      client.publish(topic, payload, {qos}, (error: any) => {
        if (error) {
        }
      });
    }
  }

  const mqttDisconnect = async () => {
    if (client) {
      if (isSub) {
        await mqttUnSub();
      }
      client.end(false, {}, () => {
        setConnectStatus('Connect');
      });
    }
  }

  const appState: MQTTStateType = {
    mqttConnect,
    mqttSub,
    mqttUnSub,
    mqttPublish,
    mqttDisconnect,
    payload,
    setPayload,
    isSub,
    saveLogin,
    userMQTT
  }

  return appState;
};

const MQTTContext: FC<TProps> = ({children}: TProps): JSX.Element => (
  <Context.Provider value={AppState()}>{children}</Context.Provider>
);

export {MQTTContext, useMQTT};
