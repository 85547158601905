const formatDay = (date: Date) => {
  return date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
}

const formatMonth = (date: Date) => {
  return date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
}

const formatTime = (date: Date) => {
  const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  return `${hour}:${minutes}`;
}

const formatDate = (date: Date) => {
  const day = formatDay(date);
  const month = formatMonth(date);
  const time = formatTime(date);

  return `${day}/${month} ${time}`;
}

const formatExpirationDate = (tmTokenExpirations: number, isTodayExpired: boolean) => {
  if (isTodayExpired) {
    const newDate = new Date();
    return formatDate(newDate);
  } else {
    const newDate = new Date(tmTokenExpirations);
    return formatDate(newDate);
  }
}

export { formatExpirationDate }