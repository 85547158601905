import { Dispatch, FC, SetStateAction, useState } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'
import { FormControl, Select, MenuItem, Box, Avatar, Typography } from '@mui/material';
import ptBrLogo from 'assets/pt-br.svg'
import usLogo from 'assets/united-states.png'
import esLogo from 'assets/espanish.svg'
import ptPtLogo from 'assets/portugal.png'

const LanguageSwitcher: FC = (): JSX.Element => {
  const { i18n, t }: UseTranslationResponse<"translation"> = useTranslation ()
  const [language, setLanguage]: [string, Dispatch<SetStateAction<string>>] = useState<string>(i18n.language ?? 'pt-BR')
  const handleChange = (value: string) => {
    setLanguage(value)
    localStorage.setItem("lang", value)
    i18n.changeLanguage(value)
  }

  return (
    <FormControl
      fullWidth
      variant='outlined'
    >
      <Select
        style={{ border: 0}}
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={language}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          }
        }}
        onChange={
          (e) => handleChange(String(e.target.value))}
      >
        <MenuItem value="pt-BR">
          <Box display="flex" alignItems="center" gap={1}>
            <Avatar src={ptBrLogo} alt="Brasil" />
            <Typography align="center">{t('portuguese')}</Typography>
          </Box>
        </MenuItem>

        <MenuItem value="es">
          <Box display="flex" alignItems="center" gap={1}>
            <Avatar src={esLogo} alt="Espanõl" />
            <Typography align="center">{t('espanish')}</Typography>
          </Box>
        </MenuItem>

        <MenuItem value="en">
          <Box display="flex" alignItems="center" gap={1}>
            <Avatar src={usLogo} alt="USA" />
            <Typography align="center">{t('english')}</Typography>
          </Box>
        </MenuItem>

        <MenuItem value="pt-PT">
          <Box display="flex" alignItems="center" gap={1}>
            <Avatar src={ptPtLogo} alt="Portuguese" />
            <Typography align="center">{t('portuguese (Portugal)')}</Typography>
          </Box>
        </MenuItem>

      </Select>
    </FormControl>
  );
}

export default LanguageSwitcher;