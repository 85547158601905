import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { requestInterceptor, requestInterceptorError, responseInterceptor, responseInterceptorError } from 'config/axiosInterceptor'


const Axios: AxiosInstance = axios.create ({
  baseURL: process.env.REACT_APP_SERVICESURL
})

Axios.interceptors.request.use (requestInterceptor, requestInterceptorError);
Axios.interceptors.response.use (responseInterceptor, responseInterceptorError);


const defaults: AxiosRequestConfig = {
  headers: {}
}

export { Axios, defaults }
