import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";

import ptBR from "i18n/pt-BR";
import en from "i18n/en";
import ptPT from 'i18n/pt-PT';
import es from 'i18n/es';

const resources: Resource = {
  ...ptBR as object,
  ...en as object,
  ...es as object,
  ...ptPT as object
};

i18n.use (initReactI18next).init ({
  resources,
  lng: localStorage.getItem("lang") ?? 'pt-BR',
  detection: {
    order: ["localStorage", "lang"],
    lookupQuerystring: "lang",
    lookupLocalStorage: "lang",
    caches: ["localStorage"],
  },
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
