import {create} from 'zustand';
import {createJSONStorage, persist} from 'zustand/middleware';
import {IAuthData, IAuthDataUser} from "../types";

interface AuthStore {
  user: IAuthDataUser;
  access_token: string;
  refresh_token: string;
  analysisMode: string;
  analysisType: string;
  expiresAt: number;
  tmTokenExpiration: number;
  signatureValidity: number;
  tokenSign: string;
  keyPass: string;
  login: (data: IAuthData) => void;
  logout: () => void;
  setTokenSign: (data: any) => void;
  setAnalysisMode: (data: string) => void;
  setAnalysisType: (data: string) => void;
  setToken: (data: string) => void;
  setKeyPass: (data: string) => void;
  refreshToken: (data: IAuthData) => void;
  getAuthData: () => any;
  isLoggedIn: () => boolean;
}

const useAuthStore = create(
  persist<AuthStore>(
    (set, get) => ({
      user: {} as IAuthDataUser,
      access_token: "",
      refresh_token: "",
      tokenSign: "",
      keyPass: "",
      analysisMode: "",
      analysisType: "",
      expiresAt: 0,
      tmTokenExpiration: 0,
      signatureValidity: 0,
      login: (data) => {
        set({
          user: data.user,
          access_token: data.access_token,
          refresh_token: data.refresh_token,
          expiresAt: new Date().getTime() + (data.expires_in * 1000) - 30000
        });
      },
      logout: () => {
        set({
          user: {} as IAuthDataUser,
          access_token: "",
          refresh_token: "",
          tokenSign: "",
          keyPass: "",
          analysisMode: "",
          analysisType: "",
          expiresAt: 0,
          tmTokenExpiration: 0,
          signatureValidity: 0,
        });
        localStorage.clear()
      },
      setTokenSign: (data: any) => {
        set(() => ({
          tokenSign: data.tokenSign,
          tmTokenExpiration: data.tmTokenExpiration,
          signatureValidity: data.validity
        }))
      },
      setAnalysisMode: (data: string) => {
        set(() => ({
          analysisMode: data
        }))
      },
      setToken: (data: string) => {
        set(() => ({
          access_token: data
        }))
      },
      setKeyPass: (data: string) => {
        set(() => ({
          keyPass: data
        }))
      },
      setAnalysisType: (data: string) => {
        set(() => ({
          analysisType: data
        }))
      },
      refreshToken: (data) => {
        set(() => ({
          access_token: data.access_token,
          refresh_token: data.refresh_token,
          expiresAt: new Date().getTime() + (data.expires_in * 1000) - 30000
        }))
      },
      isLoggedIn: () => {
        return !(new Date().getTime() > get().expiresAt || !get().access_token);
      },
      getAuthData: () => {
        return {
          user: get().user,
          access_token: get().access_token,
          refresh_token: get().refresh_token,
        } as IAuthData;
      }
    }),
    {
      name: 'userSession',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useAuthStore;